<template>
  <div v-if="currentUser" id="wallet" style="text-align: center">
    <b-jumbotron
      style="
        width: 50%;
        display: block;
        margin: 100px auto;
        opacity: 0.9;
        background-color: black;
        color: white;
      "
    >
      <template #header>Dein Wallet</template>

      <template #lead>
        Hier kannst du deinem Konto Geld hinzufügen oder entnehmen
      </template>

      <hr style="background-color: white" class="my-4" />

      <p style="font-size: 3rem">
        Dein aktuelles Guthaben: {{ currentUser.balance }} €
      </p>
      <div style="margin-top: 30px">
        <b-button
          v-b-modal.depositModal
          variant="success"
          style="margin-right: 50px"
          size="lg"
          >Einzahlen</b-button
        >
        <b-button v-b-modal.withdrawModal variant="primary" size="lg"
          >Auszahlen</b-button
        >
      </div>
    </b-jumbotron>
    <b-modal
      id="depositModal"
      @ok="deposit"
      title="Einzahlen"
      header-bg-variant="dark"
      header-text-variant="light"
      body-bg-variant="dark"
      body-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      centered
    >
      <b-form @submit.stop.prevent>
        <b-form-input
          type="number"
          v-model="depositAmount"
          :state="validationDeposit"
        ></b-form-input>
        <b-form-invalid-feedback :state="validationDeposit">
          Sie dürfen nur zwischen 1-1000 € einzahlen
        </b-form-invalid-feedback>
        <b-form-valid-feedback :state="validationDeposit">
        </b-form-valid-feedback>
      </b-form>
    </b-modal>
    <b-modal
      id="withdrawModal"
      @ok="showPayDayModal"
      title="Auszahlen"
      header-bg-variant="dark"
      header-text-variant="light"
      body-bg-variant="dark"
      body-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      centered
    >
      <b-form @submit.stop.prevent>
        <b-form-input
          type="number"
          v-model="withdrawAmount"
          :state="validationWithdraw"
        ></b-form-input>
        <b-form-invalid-feedback :state="validationWithdraw">
          Sie dürfen nur zwischen 1-1000 € auszahlen
        </b-form-invalid-feedback>
        <b-form-valid-feedback :state="validationWithdraw">
        </b-form-valid-feedback>
      </b-form>
    </b-modal>
    <b-modal
      id="tooMuchWithdrawnModal"
      centered
      ok-only
      hide-header
      body-bg-variant="danger"
      footer-bg-variant="danger"
    >
      Sie können nicht mehr auszahlen als Sie besitzen!
    </b-modal>
    <b-modal
      @ok="withdraw"
      id="payDay"
      title="Hier ist dein Cash!"
      centered
      header-bg-variant="success"
      body-bg-variant="success"
      footer-bg-variant="success"
      ok-only
    >
      <p>
        <em>{{ withdrawAmount }} €</em>
      </p>
      <img src="../assets/makeitrain.gif" alt="gif" style="width: 300px" />
    </b-modal>
  </div>
</template>

<script>
import DataService from "../services/data.service";
export default {
  name: "Wallet",
  data() {
    return {
      depositAmount: 50,
      withdrawAmount: 50,
      currentUser: null,
    };
  },
  methods: {
    deposit() {
      let data = {
        userID: this.currentUser.id,
        amount: this.depositAmount,
      };
      DataService.makeDeposit(data).then(() => {
        window.location.reload();
      });
    },
    withdraw() {
      if (this.currentUser.balance < this.withdrawAmount) {
        this.$bvModal.show("tooMuchWithdrawnModal");
      } else {
        let data = {
          userID: this.currentUser.id,
          amount: this.withdrawAmount,
        };
        DataService.makeWithdrawal(data).then(() => {
          window.location.reload();
        });
      }
    },
    showPayDayModal() {
      this.$bvModal.show("payDay");
    },
  },
  created() {
    this.currentUser = DataService.getLoggedInUser();
    if (!this.currentUser) {
      this.$router.push("/Login");
    } else {
      DataService.getBalanceOfUser(this.currentUser.id).then(
        (response) => (this.currentUser.balance = response)
      );
    }
  },
  computed: {
    validationDeposit() {
      return this.depositAmount > 0 && this.depositAmount < 1001;
    },
    validationWithdraw() {
      return this.withdrawAmount > 0 && this.withdrawAmount < 1001;
    },
  },
};
</script>

<style scoped></style>
